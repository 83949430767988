<template>
  <div class="wrap">
    <p>新增患者</p>

    <h1>niiiiiiii</h1>
    <el-form
      :model="formData"
      class="limit-width"
      :rules="rules"
      label-width="100px"
      ref="formDate"
      label-suffix=" :"
    >
      <el-form-item label="证件类型" class="limit-input" prop="type">
        <el-select v-model="formData.type">
          <el-option label="患教活动" :value="1"></el-option>
          <el-option label="其他" :value="0"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="证件号码">
        <el-input v-model="formData.id" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="姓名" class="limit-input" prop="name">
        <el-input v-model="formData.name" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="手机号码" class="limit-input" prop="name">
        <el-input v-model="formData.name" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="性别" class="limit-input" prop="type">
        <el-select v-model="formData.type">
          <el-option label="患教活动" :value="1"></el-option>
          <el-option label="其他" :value="0"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="出生日期" class="limit-input" prop="form">
        <el-select v-model="formData.form">
          <el-option label="线下" value="1"></el-option>
          <el-option label="线上" value="2"></el-option>
          <el-option label="线下与线上" value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="国籍" class="limit-input" prop=""
        ><el-cascader size="large" :options="options" v-model="selectedOptions">
        </el-cascader>
      </el-form-item>
      <el-form-item label="省市县" class="limit-input" prop="site"
        ><el-cascader size="large" :options="options" v-model="site">
        </el-cascader>
      </el-form-item>
      <el-form-item label="通信地址" class="limit-input" prop="name">
        <el-input v-model="formData.name" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="民族" class="limit-input" prop="actdate">
        <el-date-picker
          v-model="formData.actdate"
          class="search-field"
          placeholder="活动时间起"
          type="date"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>
      <el-form-item label="文化程度" class="limit-input" prop="status">
        <el-select v-model="formData.status">
          <el-option label="筹备中" :value="0"></el-option>
          <el-option label="已发布" :value="1"></el-option>
          <el-option label="进行中" :value="2"></el-option>
          <el-option label="已结束" :value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="职业" class="limit-input" prop="status">
        <el-select v-model="formData.status">
          <el-option label="筹备中" :value="0"></el-option>
          <el-option label="已发布" :value="1"></el-option>
          <el-option label="进行中" :value="2"></el-option>
          <el-option label="已结束" :value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="婚姻状况" class="limit-input" prop="status">
        <el-select v-model="formData.status">
          <el-option label="筹备中" :value="0"></el-option>
          <el-option label="已发布" :value="1"></el-option>
          <el-option label="进行中" :value="2"></el-option>
          <el-option label="已结束" :value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="身高(cm)">
        <el-input
          v-model="formData.description"
          :rows="4"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="体重(kg)">
        <el-input
          v-model="formData.note"
          :rows="4"
          autocomplete="off"
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="cancelButton">取 消</el-button>
      <el-button type="primary" @click="reEdit()">保存</el-button>
    </div>
  </div>
</template>
<script>
import { Component, Vue } from "vue-property-decorator";
@Component({
  components: {},
})
export default class AppointmentList extends Vue {
  formData = {};
  rules = [];
  options = [];
  cancelButton() {
    this.formData = {};
  }
  reEdit() {
    this.formData = {};
  }
}
</script>
<style scoped lang="scss">
.wrap {
  margin: 20px;
  background: #fff;
  padding: 20px 30px;
  border-radius: 8px;
  & > p {
    padding-bottom: 20px;
  }
}
.el-input,
.el-select {
  width: 300px;
}
</style>
